.App {
  text-align: center;
}

.pageContainer {
  max-width: 40cm;
  align-items: center;
  justify-content: center;
}

.container-fluid {
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 50px;
  max-width: 1600px; 
  min-height: 900px;
}